import React from "react";
import { BarChart, XAxis, YAxis, Bar, Tooltip, Legend, ResponsiveContainer, Brush } from "recharts";

const HistoryChart = ({ data, title, tooltipUnit, displayPower, selectedButton }) => {
  if (!data || data.length === 0) {
    return null;
  }

  const dataKey = displayPower ? "power" : "watts";
  const groupedData = data.reduce((acc, curr) => {
    const date = curr.date;
    const entry = acc[date];

    if (!entry) {
      acc[date] = { date: date, solax: 0, enphase: 0 };
    }

    if (curr.source === "solax") {
      acc[date].solax += curr[dataKey];
    } else if (curr.source === "enphase") {
      acc[date].enphase += curr[dataKey];
    }

    return acc;
  }, {});

  const dataArray = Object.values(groupedData);

  dataArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div>
      <h3 className="mainColorTextOverride" style={{ textAlign: "center" }}>{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={500} height={300} data={dataArray}>
          <XAxis
            dataKey="date"
            tickFormatter={(timeStr) =>
              selectedButton === "week" ?
                new Date(timeStr).toLocaleDateString(undefined, { weekday: 'short' }).slice(0, 1) :
                new Date(timeStr).getDate().toString()}
          />
          <YAxis width={40} />
          <Tooltip
            formatter={(value) => `${value.toFixed(1)} ${tooltipUnit}`}
            labelFormatter={(label) => {
              const dataItem = dataArray.find((d) => d.date === label);
              const total = dataItem ? (dataItem.enphase + dataItem.solax).toFixed(1) : 0;
              return (
                <span style={{ color: "#000000", lineHeight: 1.6 }}>
                  {label}
                  <br />
                  <span style={{ color: "#ff6f69" }}>total: {total} {tooltipUnit}</span>
                </span>
              );
            }}
          />
          <Legend />
          <Bar dataKey="enphase" stackId="stack" fill="#ffcc5c" />
          <Bar dataKey="solax" stackId="stack" fill="#96ceb4" radius={[10, 10, 0, 0]} />
          {selectedButton === "month" && (
          <Brush dataKey="date" height={20} stroke="#ff6f69" travellerWidth={40} />
        )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HistoryChart;
