import React from "react";
import { SolarLineChart } from "./Charts/LineChart";
import HorizontalBarChart from "./Charts/HorizontalBarChart";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useDataAndDate } from "../hooks/hookAllValues";
import "../App.css";

const DayPage = () => {
	const { selectedDate, powerData, wattsData, handleDateChange } = useDataAndDate();
	const isToday = selectedDate.toDateString() === new Date().toDateString();

	return (
		<>
			<div className="dateContainer">
				<MobileDatePicker
					label="Select date:"
					slotProps={{
						toolbar: { toolbarFormat: "dd MMMM yyyy", hidden: false },
					}}
					value={selectedDate}
					onChange={handleDateChange}
					format="dd-MM-yyyy"
					slots={<TextField />}
				/>
			</div>
			{powerData && <HorizontalBarChart data={powerData} title="Total Energy Output" tooltipUnit="kWh" />}
			{isToday && wattsData && <HorizontalBarChart data={wattsData} title="Current Wattage" tooltipUnit="Watt" />}
			{powerData && <SolarLineChart data={powerData} title="Total Energy (kWh)" tooltipUnit="kWh" />}
			{wattsData && <SolarLineChart data={wattsData} title="Watts (Watt)" tooltipUnit="Watt" />}
		</>
	);
};

export default DayPage;
