import { useState, useEffect } from "react";
import { getSunriseAndSunset } from "../utils/sunUtils";
import { processData } from "../utils/dataUtils";
import axios from "axios";

export function useDataAndDate() {
	const [data, setData] = useState();
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [powerData, setPowerData] = useState();
	const [wattsData, setWattsData] = useState();

	useEffect(() => {
		const { sunrise, sunset } = getSunriseAndSunset(selectedDate);
		axios
			.get(`/api/all_values?start_date=${sunrise}&end_date=${sunset}`)
			.then(function (response) {
				setData(response.data);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}, [selectedDate]);

	useEffect(() => {
		if (data && selectedDate) {
			setPowerData(processData(data, selectedDate, "power"));
			setWattsData(processData(data, selectedDate, "watts"));
		}
	}, [data, selectedDate]);

	function handleDateChange(e) {
		console.log(e);
		const dateValue = new Date(e);
		if (!isNaN(dateValue.getTime())) {
			setSelectedDate(dateValue);
		}
	}

	return {
		data,
		selectedDate,
		powerData,
		wattsData,
		handleDateChange,
	};
}
