import axios from "axios";

export const fetchData = async (start, end) => {
    const startDayUtc = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate())).toISOString();
    const endOfDayUtc = (date) => {
        const endOfDay = new Date(date);
        endOfDay.setUTCHours(23, 59, 59, 999);
        return endOfDay.toISOString();
    }
    const endDayUtc = endOfDayUtc(end);

    try {
        const responses = await Promise.all([
            axios.get(`/api/mydata/last_day_value?start_date=${startDayUtc}&end_date=${endDayUtc}&source=solax&source=enphase`),
            axios.get(`/api/mydata/max_day_value?start_date=${startDayUtc}&end_date=${endDayUtc}&source=solax&source=enphase`),
        ]);
        const data = {
            last_day_values: responses[0].data,
            max_day_values: responses[1].data,
        };
        return data;
    } catch (error) {
        // handle error
        console.log(error);
    }
};

export default fetchData;
