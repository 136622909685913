export const processData = (data, selectedDate, key) => {
	if (!data || !selectedDate) {
		return null;
	}
	const filteredData = selectedDate
		? data.filter((d) => new Date(d.timestamp).toDateString() === selectedDate.toDateString())
		: data;
	const combinedData = filteredData.reduce((accumulator, currentValue) => {
		const { [key]: value, timestamp, source } = currentValue;

		const time = new Date(timestamp); // Convert timestamp to Date object

		if (!accumulator[time]) {
			accumulator[time] = { time, enphase: 0, solax: 0 };
		}

		if (source === "enphase") {
			accumulator[time].enphase = value;
		} else if (source === "solax") {
			accumulator[time].solax = value;
		}

		accumulator[time]["total"] = accumulator[time].enphase + accumulator[time].solax;

		return accumulator;
	}, {});

	return Object.values(combinedData);
};
