import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import format from "date-fns/format";
import { capitalize } from "../../utils/textUtils";

function CustomTooltip({ active, payload, label, suffix }) {
  if (active && payload) {
    return (
      <div style={{ backgroundColor: "white", border: "1px solid black", padding: "5px" }}>
        <p>{format(new Date(label), 'd MMMM yyyy - H:mm')}</p>
        <div>
          {payload.map((entry, index) => (
            <div key={`item-${index}`} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <div style={{ width: "10px", height: "10px", backgroundColor: entry.color, marginRight: "5px" }}></div>
              <p style={{ margin: 0 }}>{`${capitalize(entry.name)}: ${entry.value.toFixed(2)} ${suffix}`}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}

export function SolarLineChart({ data, title, tooltipUnit }) {
  const lines = [
    { dataKey: "total", color: "#ff6f69" },
    { dataKey: "enphase", color: "#96ceb4" },
    { dataKey: "solax", color: "#ffcc5c" },
  ];

  return (
    <>
      <h3 className="mainColorTextOverride" style={{ textAlign: "center" }}>{title}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data} style={{ backgroundColor: "#fff" }}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis
            dataKey="time"
            interval={30}
            tickFormatter={(tick) =>
              new Date(tick).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
            }
            axisLine={{ strokeWidth: 2 }}
          />
          <YAxis width={40} />
          <Tooltip
            content={(props) => {
              const tooltipPayload = props.payload?.map((entry) => {
                const { name, value, stroke } = entry;
                return { name, value, color: stroke };
              });

              return <CustomTooltip {...props} suffix={tooltipUnit} payload={tooltipPayload} />;
            }}
          />
          <Legend iconType="plainline" />
          {lines.map((line) => (
            <Line key={line.dataKey} type="monotone" dataKey={line.dataKey} stroke={line.color} dot={false} strokeWidth={2}  />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
