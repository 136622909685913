import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import NowPage from "./components/DayPage";
import HistoryPage from "./components/HistoryPage";
import { Container, Paper, Typography } from "@mui/material";
import "./App.css";

export function App() {
	const [selectedTab, setSelectedTab] = useState(0);

	function handleTabChange(event, newValue) {
		setSelectedTab(newValue);
	}

	return (
		<Container>
			<div className="navBar">
				<Tabs value={selectedTab} onChange={handleTabChange} TabIndicatorProps={{ style: { background: '#ff6f69' } }}>
					<Tab label="day" sx={{ color: "#ff6f69", "&.Mui-selected": { color: "#ff6f69" } }} />
					<Tab label="history" sx={{ color: "#ff6f69", "&.Mui-selected": { color: "#ff6f69" } }} />
				</Tabs>
			</div>
			<Paper className="content">
				{selectedTab === 0 && <NowPage />}
				{selectedTab === 1 && <HistoryPage />}
			</Paper>
			<Typography className="title" variant="h6">
				Koen's Super Duper Fancy Solar App
			</Typography>
		</Container>
	);
}
