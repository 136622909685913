import React from "react";
import { BarChart, XAxis, YAxis, Bar, Tooltip, Legend, LabelList, Label, ResponsiveContainer } from "recharts";

const HorizontalBarChart = ({ data, title, tooltipUnit }) => {
  if (!data || data.length === 0) {
    return null;
  }

  const latestData = data[data.length - 1];

  if (!latestData || !latestData.enphase || !latestData.solax) {
    return null;
  }

  const CustomizedLabel = ({ x, y, width, height, value }) => {
    const formattedValue = isNaN(value) ? "-" : `${value.toFixed(0)} ${tooltipUnit}`;

    return (
      <text x={x + width / 2} y={y + height / 2} dy={4} textAnchor="middle" fill="#fff">
        {formattedValue}
      </text>
    );
  };

  return (
    <div>
      <h3 className="mainColorTextOverride" style={{ textAlign: "center" }}>{title}</h3>
      <ResponsiveContainer width="100%" height={100}>
        <BarChart layout="vertical" data={[latestData]}>
          <XAxis type="number" />
          <YAxis width={0} type="category" dataKey="source" tick={false} />
          <Tooltip
            formatter={(value) => `${value.toFixed(1)} ${tooltipUnit}`}
            labelFormatter={(label) => {
              const total = latestData ? (latestData.enphase + latestData.solax).toFixed(1) : 0;
              return `${label} total: ${total} ${tooltipUnit}` ;         
            }}
            labelStyle={{ color: '#ff6f69' }}
          />
          <Legend verticalAlign="top" />
          <Bar dataKey="enphase" stackId="stack" fill="#96ceb4" radius={[10, 0, 0, 10]}>
            <LabelList dataKey="enphase" content={<CustomizedLabel />} />
          </Bar>
          <Bar dataKey="solax" stackId="stack" fill="#ffcc5c" radius={[0, 10, 10, 0]}>
            <LabelList dataKey="solax" content={<CustomizedLabel />} />
          </Bar>
          <Label />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizontalBarChart;
