import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import HistoryChart from "./Charts/HistoryChart";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { startOfMonth, endOfMonth } from "date-fns";
import {fetchData} from "../hooks/GetLastAndMaxDayValues"
import '../App.css';

const HistoryPage = () => {
	const [data, setData] = useState(null);
	const [dateRange, setDateRange] = useState({
		start_date: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
		end_date: new Date(),
	});
	const [selectedButton, setSelectedButton] = useState("week");
	const [selectedMonth, setSelectedMonth] = useState(new Date());

	useEffect(() => {
		fetchData(dateRange.start_date, dateRange.end_date).then((data) => setData(data));
	}, [dateRange]);

	const handleButtonClick = (button, start, end) => {
		setSelectedButton(button);
		setDateRange({ start_date: start, end_date: end });
		fetchData(start, end).then(setData);
	};

	const handleWeekClick = () => {
		const endDate = new Date();
		const startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
		handleButtonClick("week", startDate, endDate);
	};

	const handleMonthClick = () => {
		const currentDate = new Date();
		const startDay = startOfMonth(currentDate);
		const endDay = endOfMonth(currentDate);
		handleButtonClick("month", startDay, endDay);
		setSelectedMonth(currentDate);
	};

	const handleMonthChange = (date) => {
		const startDay = startOfMonth(date);
		const endDay = endOfMonth(date);
		handleButtonClick("month", startDay, endDay);
		setSelectedMonth(date);
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
				<Button
					variant={selectedButton === "week" ? "contained" : "outlined"}
					className={`history-page-button ${selectedButton === "week" ? "history-page-button-selected" : ""}`}
					onClick={handleWeekClick}>
					Week
				</Button>
				<Button
					variant={selectedButton === "month" ? "contained" : "outlined"}
					className={`history-page-button ${selectedButton === "month" ? "history-page-button-selected" : ""}`}
					onClick={handleMonthClick}>
					Month
				</Button>
				{selectedButton === "month" && (
					<MobileDatePicker
						label="Select month"
						inputFormat="MMMM yyyy"
						timezone = "UTC"
						value={selectedMonth}
						onChange={handleMonthChange}
						views={["year", "month"]}
						renderInput={(params) => <TextField {...params} variant="standard" />}
					/>
				)}
			</div>
			{data && selectedButton && (
				<>
					<HistoryChart
						data={data.last_day_values}
						title="Total energy (kWh)"
						tooltipUnit="kWh"
						displayPower={true}
						selectedButton={selectedButton}
					/>
					<HistoryChart
						data={data.max_day_values}
						title="Peak Wattage (Watt)"
						tooltipUnit="W"
						displayPower={false}
						selectedButton={selectedButton}
					/>
				</>
			)}
		</>
	);
};
export default HistoryPage;
